// eslint-disable-next-line import/no-anonymous-default-export
export default {
    registration: [{
        title: 'Sign Up'
    },
    {
        title: 'プロフィール名'
    },
    {
        title: 'profile icon'
    },
    ],
    walletsend: {
        headerTitle: 'My Wallet',
        mainTitle: '送信するトークンを選んでください'
    },
    walletArcana: {
        headerTitle: 'My Wallet',
        mainTitle: 'My ARCANA',
        myPersona: 'My Persona'
    },
    walletSendAddress: {
        headerTitleAnima: 'My Wallet',
        mainTitleAnima: 'ANIMAの送信',
        textBoxLabelAnima: 'ANIMAの数量',
        headerTitleArcana: 'send',
        mainTitleArcana: '送信先アドレスを入力してください',
        textBoxLabelArcana: 'ARCANA Amount',
        headerTitlePersona: 'send Persona',
        mainTitlePersona: 'Send Token to',
        textBoxLabelPersona: 'PERSONA Amount',
        labelQR: 'QRコードを読み取る',
        labelAddress: 'アドレス',
    },
    walletsendconfirmation: {
        mainTitleAnima: 'アニマ送信',
        mainTitleArcana: 'アルカナ送信',
        mainTitlePersona: 'ペルソナ送信',
        mainTitleToken: '〇〇送信',
        labelBlockchain: 'ブロックチェーン',
        labelAnimaAmount: '数量',
        labelTokenNameSymbol: 'シンボル・トークン名称',
        labelTo: '送信先',
        labelArcanaQuantity: '送信数量',
        notifyParagraph: '以下の取引を実行してもよろしいですか？',
    },
    walletSupport: {
        headerTitle: 'support',
        notifyParagraph: 'ご不明な点がございましたら、お気軽にお問い合わせください',
    },
    walletAbsorb: {
        headerTitle: 'My Wallet',
        mainTitle: '食べる',
        absorber: 'イーター',
        descAbsorber: 'イーターとなるペルソナを選択',
        target: '吸収対象',
        descTarget: '吸収対象を選択',
        FOR: 'FOR',
        ABS: 'ABS',
        DFT: 'DFT',
        MND: 'MND',
        INT: 'INT',
        EXP: 'EXP',
        absorbSuccessMsg: '吸収は正常に完了しました。',
        absorbFailMsg: '吸収に失敗しました。',
    },
    walletAbsorbList: {
        headerTitle: 'My Wallet',
        mainAbsorberTitle: 'ペルソナを選択',
        mainTargetTitle: 'アルカナを選択',
    },
    walletReceive: {
        title: '受信',
        headerTitle: 'My Wallet',
        labelMyAddress: 'My Address',
        labelQrCode: '自分のQRコード',
        notifyParagraph: 'QRコードを撮影するか、上のアドレスをコピーすることでトークンを受け取る事が可能です。'
    },
    wallet: {
        headerTitle: 'My Wallet',
        iconDashboard: 'Dashboard',
        iconSend: 'Send',
        iconReceive: 'Receive',
        iconTXHistory: 'TX history',
        iconSettings: 'Wallet Settings',
        iconManaAddress: 'Mana Address',
        iconSupport: 'Support',
        titleAddress: 'アドレス'
    },
    walletTransaction: {
        labelTxHash: 'TXハッシュ',
        labelBlockChain: 'ブロックチェーン',
        labelSymbol: 'シンボル',
        labelTokenName: 'トークン',
        labelQuantity: '数量',
        labelTimestamp: 'タイムスタンプ',
        labelTo: '送信先',
        labelFrom: '送信元',
        labelSlash: '・',
        labelItem: '種類',
        labelDate: '日付',
        labelAmount: '数量',
        labelTokenID: 'トークンID',
        headerTitle: 'TX 履歴',
    },
    walletDrawHistory: {
        title: 'ドロー履歴',
        labelDate: '日付',
        labelStatus: 'ステータス',
        labelSuccess: '成功',
        labelPending: 'ペンディング',
        lableDrawChainName: 'ドローチェーン名',
        lableUsedPersona: '使用ペルソナ',
    },
    walletManaAddress: {
        title: 'MANA アドレス',
        labelTokenID: 'トークンID',
        labelAction: 'アクション',
        labelDate: '日付',
        labelStatus: 'ステータス',
        labelCompleted: '完了',
        labelIncomplete: '未完了',
        labelFailed: '失敗',
        labelRestore: '再生成'
    },
    walletTableLabel: {
        labelAction: 'アクション',
        labelTo: '送信先',
        labelFrom: '送信元',
        labelSlash: '・',
        labelItem: '種類',
        labelDate: '日付',
        labelAmount: '数量',
        labelTokenID: 'トークンID',
        labelTxID: 'TX ID',
    },
    signupForm: {
        mainTitle: 'ANICANA IDの生成',
        notifyParagraph: '本サービスの利用にあたり、利用規約とプライバシーポリシーに同意してください',
        checkBoxTermofus: '利用規約',
        checkBoxPrivacyPolicy: 'プライバシーポリシー'
    },
    profileNameForm: {
        mainTitle: 'プロフィール名',
        paceholderProfileName: 'プロフィール名',
        notifyParagraph: `プロフィールに表示される名称を設定してください
        \n
        今設定したくない場合は、後ほど「プロファイル」メニューで設定可能です`
    },
    mobileVerificationForm: {
        mainTitle: 'メールアドレスを忘れた方',
        placeholderPhone: '携帯番号',
        notifyParagraph: 'アカウントに登録されている携帯電話番号を入力してください。 ご登録のメールアドレスをSMSで送ります。',
        sendSmsBtn: 'SMS送信',
    },
    passwordResetForm: {
        mainTitle: 'パスワードリセット',
        paceholderEmail: 'メールアドレス',
        placeholderPhone: '携帯番号',
        notifyParagraph: 'ご登録済みのメールアドレスにメールが届きます。「パスワード再設定」のURLにアクセスし、新しいパスワードを設定してください',
    },
    privacy: {
        mainTitle: `プライバシーポリシー（VERSION 2.0）`,
        notifyParagraph: `
        <div>
        <div class="text-center"><b>DEAR USERS!</b></div>
        <div class="text-center">このプライバシーポリシーをご覧いただきありがとうございます。 <br>当社がユーザーの個人情報を処理する理由と方法については、以下をお読みください。</div><br/>
        <div class="row">
            <div class="row-no">1.</div>
            <div class="row-item"><b class="m-l-lg-15">本プライバシーポリシーについて</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                本プライバシーポリシーは、ANICANA ΘΥΡΑ (THÝRA)（以下「ポータル」という）を使用する際の個人情報の処理方法についてのお知らせです。InfiCreo Zrt.（以下「当社」という。）は欧州連合（以下「EU」という。）に所在しているため、本プライバシーポリシーはEU一般データ保護規則（以下は「GDPR」という）に沿って作成されたものです。</br>
                可能な範囲で全てを網羅した包括的なポリシーとして作成しておりますが、ご不明な点がございましたらinfo@anicana.orgにお問い合わせいただくか、ポータルの「お問い合わせ」フォームをご利用ください。</br>
                ※なお、日本語により記載されている本プライバシーポリシーは、英文によるプライバシーポリシーを訳したものであり、解釈、ニュアンス等が異なる場合がありますので、正式には英文によるプライバシーポリシーを参照してください。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">2.</div>
            <div class="row-item"><b class="m-l-lg-15">個人情報の処理者について</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                個人情報とは、基本的に個人に関するあらゆる情報（以下「個人情報」という）をいいます。個人情報を使用しての全ての行い、データの収集、データベースの一部の作成、保存などは個人情報の処理と見なされます。こういった処理などについて責任を負うのはポータルを運営する会社である当社です。<br/>
                <b>
                    当社はEU加盟国であるハンガリーに所在しています。<br/>
                    住所：H-8200 Veszprém, Viola utca 2., Hungary<br/>
                    登記番号：19-10-500392<br/>
                </b>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">3.</div>
            <div class="row-item"><b class="m-l-lg-15">個人情報の取り扱いについて</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                当社は、世界で最も厳しいプライバシー法の1つであるGDPR(EU一般データ保護規則)に従って運営を行い、ユーザーの個人情報保護に関しては、真剣に取り組んでいます。<br/>
                個人情報の取り扱いは以下の通りです。<br/>
                <ul>
                    <li>当社は、適用されるすべての法律に従って、透明性のある方法でユーザーの個人情報を処理します。これは、ユーザーの個人情報処理方法と理由、問い合わせに対応できる場を設け、およびユーザーが持つ権利に関する情報を常に提供することを意味します。開示されていない、またはあいまいな目的で個人情報を処理することはありません。</li>
                    <li>当社は処理される個人情報を必要不可欠なものに限定しています。過剰な個人情報を収集または処理を行わない上に、将来のために「あると便利」という理由で個人情報を蓄えません。</li>
                    <li>特定の目的のために必要でない限り、個人情報を無期限に保管しません。またそれ以外は、当社とユーザーの最後の関連するやりとりの日付から始まる法定期間中、ユーザーの個人データを保管します。当期間が終了すると、当社はユーザーの個人情報を削除します。</li>
                    <li>当社は、個人情報が正確かつ最新の状態に保たれるように最善を尽くします。</li>
                    <li>当社は、個人情報のセキュリティを確保し、不正アクセス、開示、または個人情報を危険にさらす可能性のあるその他の事象のリスクを最小限に抑えます。これには、適切な情報技術、物理的および組織的な管理が含まれます。</li>
                    <li>ユーザーご自身の個人情報をどうするかは、ユーザーが決めます。従って、当社はいつでもユーザーの質問に答える準備ができ、ユーザーの懸念に対処するために必要な措置を講じています.</li>
                </ul>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">4.</div>
            <div class="row-item"><b class="m-l-lg-15">個人情報の処理目的について</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                当社は、各種コンテンツのウエブポータルであるポータルの運営者です。<br/>
                ポータルにアクセスする際に提供された個人データ（登録およびログインデータ）を処理することはありませんが、特定の個人データを使用して、お問合せ内容に回答し、プライバシー権を行使できるようにする場合があります。<br/>
                当社の情報処理の詳細については、以下をお読みください。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">4.1. </div>
            <div class="row-sub-item"><b>質問への回答、権利の行使</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                ユーザーのお問い合わせや懸念事項に対処するために、特定の個人情報、具体的にはユーザー名、電子メールアドレス、およびメッセージの内容が必要になります。<br/>
                この情報は以下の目的に使用されます：<br/>
                <ul>
                    <li>プライバシー権の行使を希望する場合も含めて、ユーザーの質問、お問い合わせに答えるため</li>
                    <li>ユーザーの権利の行使を可能にするGDPR下での義務、および適用される国の消費者保護法の下での義務を果たすため</li>
                </ul>
                当社は、法的義務に基づいてユーザーの個人データを使用し、ユーザーが自分の権利を行使し、ユーザーの個人データを管理できるようにします。また、当社は、適用される消費者保護法に基づいて、ユーザーのお問い合わせに回答し対処する義務があります。特定の質問またはお問い合わせに回答する明確な法的義務が当社にない場合、当社のデータ処理の法的根拠は、ユーザー対応し、ユーザーフレンドリーなサービスを提供するという当社の正当な利害関係になります。<br/>
                ユーザーが当社に苦情を申し立てた場合、当社はその苦情に含まれるユーザーの個人情報と当社の回答を苦情の提出日から3年間保管します。苦情と見なすことができない質問または問い合わせ、およびそれに対する当社の回答は、以下の 4.2.項 (法的請求) で指定された目的のために保管する正当な理由がない限り、そのような質問または問い合わせまたは当社の回答も回答後に削除されます。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">4.2. </div>
            <div class="row-sub-item"><b>法的請求</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                ユーザーと当社の間で法的な議論が決して起こらないことを望んでいますが、そのような場合、当社の主張を証明し、当社の立場を弁護するために、特定の個人情報を処理する必要があります。これには、ユーザーと当社とのコミュニケーションの内容、ユーザーの質問、お問い合わせ、苦情などに当社が回答したという証拠、およびその他の関連情報が含まれます。<br/>
                当社は、当社の法的立場を擁護し、法的な議論が発生した場合に法的請求を行使するという正当な利益に基づいて、前述のような個人情報を使用します。<br/>
                この目的のために、当社は、当社とユーザーの最後の関連するやりとりの日付から始まる法定期間中、ユーザーの個人データを保管します。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">5.</div>
            <div class="row-item"><b class="m-l-lg-15">個人情報の共有先</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                すべての個人情報は、適切に保護されたサーバーに保存され、処理されます。当社が使用するすべてのサーバーは、欧州経済領域 （EEA）または「EUの法律によって個人情報に適切なレベルの保護を提供する」と認められている日本国に位置しています。日本がどのように個人情報の保護を保証しているかについて詳しくは、こちらをご覧ください。<br/>
                <a href="https://ec.europa.eu/commission/presscorner/detail/en/MEMO_19_422">https://ec.europa.eu</a> <br/>
                当社は、適切なレベルの保護を提供しない国または受信者に個人情報を転送しません。そのような転送がどうしても必要になった場合、当社は、その目的のために特別に設計された契約条項など、個人情報を保護するための保証メカニズムを適用します。ご要望があれば、これらの保証の詳細をお知らせします。<br/>
                当社はユーザーの個人情報を機密に扱い、ここに記載されている場合を除き、第三者に開示しません。<br/>
                <ul>
                    <li>世界で最も広く受け入れられているクラウド プラットフォームであるアマゾン ウェブ サービス (Web Services EMEA SARL、登録所在地: 38 Avenue John F. Kennedy, L-1855, Luxembourg) は、当社にクラウド ストレージ サービスを提供します。</li>
                    <li>Google、当社の電子メール サービス プロバイダー (Google Ireland Limited、登録所在地: Gordon House, Barrow Street, Dublin 4 Ireland);</li>
                    <li>法的な議論または公式の手続きの場合、機密保持要件に拘束される法律顧問、または警察や裁判所などの代理の当局に特定の個人情報を提供する必要がある場合。</li>
                </ul>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">6.</div>
            <div class="row-item"><b class="m-l-lg-15">ユーザーの持つ権利について</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                ここに記載されている権利については、いつでも当社に連絡することできます（1条を参照）。<br/>
                お問い合わせの際は、ご質問や要望はご自身でご記入ください。弁護士のように表現する必要はありません。当社の個人情報の処理に関する懸念事項と、当社に何をしてほしいかをお知らせください。<br/>
                ユーザーの権利の行使は無料です。(ユーザーの要求が明らかに根拠のない、または過度である場合にのみ、合理的な料金を請求する場合があるが、その場合は、その料金の金額を事前に通知します。) すべてのお問い合わせには、可能な限り早急に対応するが、遅くとも1か月以内に必ず対応します。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.1. </div>
            <div class="row-sub-item"><b>アクセス権</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                当社がユーザーに関する個人情報を処理しているかどうか、また、処理している場合は、どの個人情報を、なぜ、どのくらいの期間、誰と共有するのか、どのような権利を行使できるのか、いつでも当社に尋ねることができます。<br/>
                また、当社が保有するユーザーの個人情報のコピーを提供するよう当社に依頼することもできます。 これを否定できる唯一のケースは、それが他人の権利を侵害する場合です。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.2. </div>
            <div class="row-sub-item"><b>修正の権利</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                当社が保有しているユーザーの個人情報が正しくないと思われる場合は、いつでも更新するよう当社に依頼することができます。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.3. </div>
            <div class="row-sub-item"><b>消去する権利（「忘れられる権利」）</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                ユーザーには「忘れられる権利」があります。これは基本的に、以下の場合に当社が保有するユーザーに関する個人情報を削除しなければならないことを意味します。<br/>
                <ul>
                    <li>当社にはもう必要ない場合</li>
                    <li>ユーザーが以前に個人情報処理に対して行った同意を撤回した (そのような同意が以前に与えられていた) 場合</li>
                    <li>ユーザーが当社による個人情報の処理に異議を申し立て、当社がユーザーの異議を覆す利益を有していることを証明できない場合</li>
                    <li>法律で義務付けられている場合</li>
                </ul>  
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.4. </div>
            <div class="row-sub-item"><b>個人情報の処理を制限する権利</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                個人情報の処理を制限するよう当社に依頼することができます。これは、個人情報を保存する以外に使用しないことを意味します。これは、個人情報を削除してほしくないが、当面の間、実際の使用を停止したい場合に役立ちます。<br>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.5. </div>
            <div class="row-sub-item"><b>異議を申し立てる権利</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                ユーザーは、当社によるユーザーの個人情報の処理に対して異議を唱える権利を有します。そのような場合、当社は、それを処理することに優先権を持っていることを証明する必要があります (当社の法的権利および地位の擁護または法的義務の履行など)。これを証明できない場合は、情報を削除する必要があります。<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.6. </div>
            <div class="row-sub-item"><b>苦情を申し立てる権利、訴訟を起こす権利</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                苦情のないように最善を尽くします。<br/>
                それでも、個人情報が違法に処理されていると思われる場合は、管轄当局に苦情を申し立てることができます。当社はハンガリーで登録された会社であるため、この機関はHungarian National Authority for Data Protection and Freedom of Informationになります。<br/>
                URL: <a href="https://www.naih.hu/about-the-authority">https://www.naih.hu/about-the-authority</a><br/><br/>
                住所: H-1055 Budapest, Falk Miksa utca 9-11.<br/>
                郵便: 1374 Budapest, Pf.:603.<br/>
                電話番号: +36-1-391-1400<br/>
                Fax: +36-1-391-1410<br/>
                Email: ugyfelszolgalat@naih.hu<br/><br/>
                また、司法救済を受ける権利もあります。ハンガリーの裁判所の詳細については、以下をご覧ください。<br/>
                <a href="https://birosag.hu/en">https://birosag.hu/en</a><br/> <br/>
            </div>
        </div>
        <div class="text-center">* * *</div>
        発効日: 2023年1月24日<br/>
        </div>`,
        langClass: 'jp_privacy',
    },
    termOfUse: {
        mainTitle: `<div class="jp-fw-400">利用規約</div>`,
        notifyParagraph: `
        <div>
        <b>Version: 2.0</b><br/>
        <span class="jp-fw-400">発効日：2023年1月24日</span><br/>
        <br/>
        アニカナテュラー（ANICANA θυρα）の利用規約（以下「本規約」といいます。）をよくお読みください。ポータルを訪問することにより、以下を了承したことを表明し、同意するものとみなされます。<br/>
        <br/>
        同意する内容には以下が含まれます：<br/>
        <ol style="margin-left: 1.5rem">
          <li>アニカナテュラーおよびサービス（以下に定義）に関して、当社が表明または保証を行わないこと、およびアニカナテュラーおよびサービスが「現状のまま」かつ保証なしで提供されること（第10条）に対するユーザーの同意。</li>
          <li>当社の責任の制限、およびユーザーと第三者の間の請求に基づく責任から当社を解放するためのユーザーの同意（第10条）。</li>
        </ol>
        <br/>
        本規約には、InfiCreo Zrt.（以下「当社」といいます。所在地：8200 Veszprém, Viola utca 2., Hungary、登記番号：19-10-500392）の提供するアニカナテュラーのサービス（以下「本サービス」といいます。）の利用条件を定めています。本サービスをユーザーとしてご利用になる方は、本規約をご確認いただき、ご同意をいただいたものとされますので、以下を必ずご確認ください。<br/>
        <br/>
        <b>第1条 （適用）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本規約は、本サービス（第2条に定義）の提供条件及び本サービスの利用に関する当社とユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。</li>
          <li>当社が本サービス上で掲載する本サービス利用に関するルール（以下「個別規約」といいます。）も、本規約と一体のもとして本サービスの利用に適用され、個別規約と本規約とで内容が異なる場合は、本規約が優先して適用されるものとします。</li>
          <li>当社は、ユーザーに事前告知をすることなく、本規約を合理的な範囲内で変更することができます。本規約が変更された場合、変更を告知した後に変更後の規約が適用されるものとし、ユーザーは本規約の変更後に本サービスを利用した場合には、変更後の規約に同意したものとみなされます。</li>
        </ol>
        <br/>
        <b>第2条 (用語の定義)</b><br/>
        本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。<br/>
        <ol style="margin-left: 1.5rem">
          <li>「アニカナテュラー利用規約」とは、本規約及び当社とユーザーとの間で締結する、本サービスの利用契約をいいます。</li>
          <li>「本サイト」とは、本サービスを提供するために当社が運営するウェブサイト「アニカナテュラー」をいいます。</li>
          <li>「本サービス」とは、本サイトにおいて当社及び当社が提携または委託した会社（以下「当社提携先等」といいます。）が提供するインターネットその他のネットワークを通じて行う各種コンテンツの配信、アニカナウォレットに関するアプリケーション、その他本サイト内にて提供するサービス一切をいいます。</li>
          <li>「ユーザー利用申込」とは、本サービス利用希望者が本規約に同意し、第三者の提供する認証情報の参照を経て、本サービスの提供を申込むことをいいます。</li>
          <li>「ユーザー」とは、当社が提供する本サービスを利用する全ての者をいいます。</li>
          <li>「ユーザー情報」とは、本サービスの利用のために、ユーザーが第三者へ登録した情報一切をいい、当社が取得・管理しているものではありません。</li>
          <li>「個人情報」とは、ユーザー情報のうち、電子メールアドレス等、特定の個人を識別できる情報の一切をいいます。</li>
          <li>「認証情報」とは、ユーザー情報のうち当社がユーザーからの接続を認証するために必要な情報でLEVIAS IDやパスワードを含む情報をいいます。</li>
          <li>「LEVIAS ID」とは、レヴィアス株式会社が保有、運営、管理するIDで、本サービスではユーザーを識別するためにID番号とパスワードを参照しています。</li>
          <li>「アニカナウォレット」とは、ブロックチェーン（ANICANA Chain）上のNFTを含むトークンを格納するための第三者サービス提供者からユーザーに提供されるウォレットをいいます。任意にトークンを出し入れすることができ、本サービスを利用するにあたって必要となります。</li>
        </ol>
        <br/>
        <b>第3条 (本サービス)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本サービスの名称を「アニカナテュラー」といいます。</li>
          <li>本サービスは、当社または当社提携先等が運営する各種コンテンツの配信、ANICANA Chain上で起動するアプリケーションのユーザーインターフェースの提供、ニュース等メディアコンテンツ配信するためのプラットフォームをいいます。</li>
          <li>当社は必要に応じて、ユーザーの事前の了承を得ることなく本サービスに新しいサービスの追加またはサービス内容の変更を行うことができるものとし、ユーザーはこれらをあらかじめ承諾した上で本サービスを利用するものとします。</li>
          <li>各事業会社が運営するコンテンツ内のアイテム、プレイ権、コンテンツ内で利用できる電子マネー等、価値または権利を表すデジタル資産は、各事業会社が販売しており、当該コンテンツ内でのこれらサービスは本サービスに含まれません。</li>
          <li>各事業会社が運営するコンテンツ内でアイテム、プレイ権、コンテンツ内で利用できる電子マネー等の購入等に関することは、当該コンテンツを運営する各事業会社が定める利用規約が適用されます。当社は価値または権利を表すデジタル資産の保管、交換、譲渡、取引または管理を含む金融または非金融サービス、またはこれらの活用を可能にするサービスを提供せず、そのような資産または活用に関するアドバイスも提供しません。</li>
          <li>本サービス内で表示されるユーザーのアニカナウォレット（マイ・ウォレット）情報を含むNFT等のトークンに関連する情報は、ブロックチェーン上のウォレットまたは第三者が提供する情報を参照・表示しているもので、当社がその情報を保有または管理しているものではありません。</li>
        </ol>
        <br/>
        <b>第4条 (ユーザー利用申込)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、第三者の提供する認証情報の参照を経て、本サービスの利用を申請するものとします。</li>
          <li>本サービスの利用にあたっての利用申込は、所定の第三者のユーザー情報登録を行い、そのユーザー情報のうち認証情報を当社が参照することをもって本サービスにおける利用申込とみなされ、正常に認証された場合、本サービスの利用が可能となります。</li>
          <li>当社は、前項の本サービスの利用申込にあたり、所定の第三者が提供する認証情報の参照のみを行い、ユーザー情報及びユーザーの個人情報に該当する情報は一切提供を受けません。</li>
          <li>利用希望者は、本サービスの利用申込にあたり、真実、正確かつ最新の情報が所定の第三者のユーザー情報に登録されていなければなりません。</li>
          <li>当社は、第1項に基づき利用申込をした者が、以下の各号のいずれかの事由に該当する場合は、利用申込を承諾しないことがあります。</li>
            <ol class="round-bracket" style="margin-left: 1.5rem">
              <li>本規約に違反するおそれがあると当社が判断した場合</li>
              <li>当社が参照したユーザー情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
              <li>過去に本サービスの利用を取り消された者である場合</li>
              <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</li>
              <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合</li>
              <li>国法及び条例、通達等によるアクセス制限地域等からの利用申込と判断した場合</li>
              <li>その他、当社が利用申込を適当でないと判断した場合</li>
            </ol>
          </li>
          <li>当社は、前項その他当社の基準に従って、利用希望者の利用申込の可否を判断します。利用希望者のユーザーとしての利用申込は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）がユーザーと当社の間に成立します。</li>
          <li>当社は、前項の判断において、利用希望者の利用申込が認められなかった場合、当該利用希望者にその理由を明らかにする義務を負わないものとします。</li>
          <li>ユーザーは、当社が利用申込を承認した時点で、本サービスの提供を受けることに同意したものとみなします。</li>
          <li>ユーザーは、本サービスの提供を受ける権利について、譲渡、貸与、名義変更、担保供与し、その他第三者の利用に供する行為をすることはできません。</li>
          <li>本サービスには、当社提携先等が運営するコンテンツ固有の情報の登録の設定を必要とするサービスが含まれます。ユーザーがそれら個別のサービスを利用するにあたっては、それぞれのサービスが指定するガイドラインに沿って必要な情報を正確に登録の上、利用するものとします。</li>
          <li>ユーザーは自己の責任と費用負担によって、認証情報の管理を行うものとし、認証情報の管理不十分による情報の漏洩、使用上の過誤、第三者使用、不正アクセス、亡失・紛失等による損害の責任はユーザーが追うものとし、当社は一切、責任を負わないものとします。</li>
        </ol>
        <br/>
        <b>第5条 (ユーザー情報の変更と認証情報の管理)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>ユーザーは自己の責任と費用負担によって、認証情報の管理を行うものとし、認証情報を第三者に利用させ、貸与、譲渡、売買、質入、公開等をすることはできません。</li>
          <li>認証情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任はユーザーが負うものとし、当社は一切責任を負わないものとします。万一、認証情報が不正に利用されたことにより当社に損害が生じた場合、ユーザーは当該損害を賠償するものとします。</li>
          <li>ユーザーは、ユーザー情報に変更が生じた場合又は認証情報を第三者に知られた場合、認証情報が第三者に使用されている疑いのある場合には、ユーザー情報を管理する者の規約及びその者の定める方法により、直ちにその旨届け出るものとします。</li>
          <li>ユーザーが前項の届出をしなかったことにより、本サービスを利用できない等の不利益を被った場合、当社は一切の責任を負わないものとします。</li>
        </ol>
        <br/>
        <b>第6条（LEVIAS ID）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本サービスの利用にあたっては、レヴィアス株式会社が運営・管理するLEVIAS ID登録が必要となります。</li>
          <li>当社はLEVIAS IDについて、参照のみを行っており、LEVIAS IDに関連するユーザー固有の情報の保有、維持、管理等一切について当社は行っておらず、責任を負いません。</li>
        </ol>
        <br/>
        <b>第7条 (ユーザー情報・個人情報)</b><br/>
        <span style="margin-left: 1.5rem">当社のプライバシーポリシーを参照してください。</span><br/>
        <br/>
        <b>第8条（利用契約の解除）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>当社は、ユーザーが以下のいずれかの事由に該当する場合、またはその恐れがあると当社が判断した場合、ユーザーへ事前に通告・催告することなく、かつ、ユーザーの承諾を得ずに、当社の裁量によりただちに本サービスの利用を停止することができるものとします。
            <ol class="round-bracket" style="margin-left: 1.5rem">
              <li>本規約に違反した場合</li>
              <li>ユーザー情報に虚偽、過誤がある場合</li>
              <li>当社、他のユーザー、配信ゲーム会社その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合</li>
              <li>問い合わせなどにより当社が取得した電子メールアドレスが機能していないと判断される場合</li>
              <li>手段の如何を問わず、本サービスの運営を妨害した場合</li>
              <li>第三者になりすまして利用申込を行った場合</li>
              <li>重複した利用申込があった場合</li>
              <li>第10条に定める禁止事項を行った場合</li>
              <li>ユーザーが死亡した場合</li>
              <li>6ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合</li>
              <li>第4条第4項各号に該当する場合</li>
              <li>その他、当社がユーザーとして不適切と判断した場合</li>
            </ol>
          </li>
          <li>前項の場合において、当社が何らかの損害を被った場合、ユーザーは当社に対して損害の賠償をしなければならないものとします。</li>
          <li>当社はユーザーに対して、第1項の利用停止の理由を開示する義務を負わないものとします。</li>
          <li>本条に定める利用停止により、ユーザーと当社の間で締結された契約が解約されるものとし、ユーザーは本サービスを利用する権利を全て失うものとします。</li>
        </ol>
        <br/>
        <b>第9条 (免責)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>当社は、本サービスにつき、正確性、有用性、適法性、瑕疵の不存在、セキュリティー、特定目的への適合性、権利侵害の不存在その他一切の事項について如何なる保証も行うものではありません。</li>
          <li>当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、情報の削除または消失､ユーザーの利用の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してユーザーが被ったいかなる損害についても、賠償する責任を一切負わないものとします。</li>
          <li>ユーザーが、本サービスを利用することにより、第三者に対し損害を与えた場合、ユーザーは自己の費用と責任においてこれを賠償するものとします。</li>
          <li>当社がユーザーと第三者との間の契約、またはユーザーと第三者との間のその他の取引の当事者ではない場合、ユーザーは、当社がそのような契約またはその他の取引を管理できないことに同意し、従って、当社はユーザーが第三者との紛争に起因または関連する請求、要求、損害および責任から免除されます。</li>
          <li>本サービスに関連してユーザーと他のユーザー、コンテンツ提供会社その他の第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。</li>
          <li>当社は本サービスに発生した不具合、エラー、アクセス過多による障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。</li>
          <li>当社は、本サービス及び広告主、コンテンツ提供会社を含む第三者のウェブサイトからのダウンロードやコンピューターウイルス感染等により発生した、コンピューター、回線、ソフトウェア等の損害について、賠償する義務を一切負わないものとします。</li>
          <li>本サービスならびに本サイト上のコンテンツおよび情報は、当社がその時点で提供可能なものとします。当社は提供する情報、ユーザーが登録・送信（発信）する文章その他のコンテンツおよびソフトウェア等の情報について、その完全性、正確性、適用性、有用性、利用可能性、安全性、確実性等につきいかなる保証も一切しません。</li>
          <li>当社は、ユーザーに対して、適宜情報提供やアドバイスを行うことがありますが、その結果について責任を負わないものとします。</li>
          <li>本サービスが何らかの外的要因により、データ破損等をした場合、当社はその責任を負いません。</li>
          <li>当社は、サービスを通じて行われたコンテンツ提供会社を含む第三者とユーザーとの取引（コンテンツ内アイテムの交換等）について、一切の責任を負わないものとし、全ての取引は当該第三者とユーザーの責任においてなされるものとします。</li>
          <li>当社は、本規約又は個別規約等が消費者契約法2条3項の消費者契約に該当し、本規約及び個別規約のうち、当社の損害賠償責任を完全に免責する規定が適用されない場合、ユーザーに発生した損害が当社の債務不履行又は不法行為に基づくときに、当該ユーザーが直接被った損害に限り損害賠償責任を負うものとします。ただし、当社に故意又は重過失があることが必要です。</li>
          <li>アニカナテュラーに第三者が提供する他のWebサイトやリソースへのリンクが含まれている場合、これらのリンクは情報提供のみを目的として提供されています。そのようなWebサイトおよびリソースには、異なる利用規約およびプライバシーポリシーが適用される場合があります。当社は、これらのウェブサイトまたはリソースのコンテンツを管理することはできず、如何なる場合でも当社はそれらに対して責任を負いません。</li>
        </ol>
        <br/>
        <b>第10条 (禁止事項)</b><br/>
        <ol>
        ユーザーは、本サービスの利用に際して、以下の行為を行ってはならないものとします。なお、ユーザーが以下の行為をした場合、直ちに本サービスの利用停止等の措置を取らせていただくことがあります。
          <ol class="round-bracket" style="margin-left: 1.5rem">
            <li>当社、他のユーザーもしくはその他の第三者（以下「他者」といいます。）の著作権、商標権等の知的財産権を侵害する行為、または侵害するおそれのある行為</li>
            <li>犯罪行為に関連する行為又は公序良俗に反する行為</li>
            <li>他者の財産、プライバシー、肖像権その他の権利を侵害する行為、または侵害するおそれのある行為</li>
            <li>特定の個人の個人情報の提供</li>
            <li>他者を差別もしくは誹謗中傷し、または他者の名誉もしくは信用を毀損する行為</li>
            <li>過度あるいは不適切に特定の外部Webサイトへ誘導することが目的と判断される行為</li>
            <li>児童・若年者に対し悪影響があると判断される行為</li>
            <li>アクセス可能な本サービスのコンテンツもしくは情報または他者のコンテンツもしくは情報を改ざん、消去する行為</li>
            <li>当社または他者になりすます行為（詐称するためにメールヘッダ等の部分に細工を行う行為を含みます。）</li>
            <li>有害なコンピュータプログラム等を送信し、または他者が受信可能な状態におく行為</li>
            <li>他者に対し、無断で、広告・宣伝・勧誘等の電子メールもしくは嫌悪感を抱く電子メール（そのおそれのある電子メールを含みます。）を送信する行為、他者のメール受信を妨害する行為、連鎖的なメール転送を依頼する行為または当該依頼に応じて転送する行為</li>
            <li>通常に本サービスを利用する行為を超えてサーバーに負荷をかける行為もしくはそれを助長するような行為、その他本サービスの運営・提供もしくは他のユーザーによる本サービスの利用を妨害し、またはそれらに支障をきたす行為</li>
            <li>サーバー等のアクセス制御機能を解除または回避するための情報、機器、ソフトウェア等を流通させる行為</li>
            <li>本サービスによって提供される機能を複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳あるいは解析する行為</li>
            <li>本人の同意を得ることなく、または詐欺的な手段（いわゆるフィッシングおよびこれに類する手段を含みます。）により他者のユーザー情報を取得する行為</li>
            <li>本サービスの全部または一部を商業目的で、使用方法を問わず利用する行為（それらの準備を目的とした行為も含みます。）</li>
            <li>法令に基づき監督官庁等への届出、許認可の取得等の手続きが義務づけられている場合に、当該手続きを履行せずに本サービスを利用する行為、その他当該法令に違反し、または違反するおそれのある行為</li>
            <li>本サービスの運営を妨害する行為、他者が主導する情報の交換または共有を妨害する行為、信用の毀損または財産権の侵害等の当社または他者に不利益を与える行為</li>
            <li>メール送信、同様の問い合わせの繰り返しを過度に行い、または義務や理由のないことを強要し、当社の業務に著しく支障を来たす行為</li>
            <li>上記各号の他、法令もしくは本利用規約に違反する行為、または公序良俗に違反する行為（暴力を助長し、誘発するおそれのある情報もしくは残虐な映像を送信もしくは表示する行為や心中の仲間を募る行為等を含みます。）</li>
            <li>上記各号のいずれかに該当する行為（当該行為を他者が行っている場合を含みます。）が見られるデータ等へ当該行為を助長する目的でリンクを張る行為</li>
            <li>その他当社がユーザーとして不適当と判断した行為</li>
          </ol>
        </ol>
        <br/>
        <b>第11条 (権利帰属)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本サービスに関する著作権等の知的財産権、その他の権利は、当社またはその権利許諾者に帰属します。</li>
          <li>本サービスにて配信される各種コンテンツに関する著作権等の知的財産権、その他の権利は、各種コンテンツを配信する事業会社またはその権利許諾者に帰属します。</li>
          <li>アニカナテュラー上で、またはアニカナテュラーを通じて、個人情報以外のユーザー情報を利用可能にすることにより、ユーザーは、当社のサービスの提供、開発、強化において、かかるユーザー情報を使用するための限定的、非独占的、使用料無料、全世界的、取消不能、サブライセンス可能、および譲渡可能なライセンスを当社に付与します。</li>
          <li>ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、および投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</li>
          <li>ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。</li>
          <li>本規約に基づく本サービスの利用許諾は、本サービスに関する当社またはその権利許諾者の著作権等の知的財産権、その他の権利の使用許諾を意味するものではありません。</li>
        </ol>
        <br/>
        <b>第12条 (本サービスの停止、中止、終了)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部又は一部を停止又は中断することができるものとします。
          <ol class="round-bracket" style="margin-left: 1.5rem">
            <li>本サービスに係るコンピューターシステムの点検又は保守作業を定期的又は緊急に行う場合</li>
            <li>火災、停電、天災地変等の不可抗力により本サービスの運営が不能となった場合</li>
            <li>戦争、内乱、暴動、騒擾、労働争議等により本サービスの運営が不能となった場合</li>
            <li>本サービス提供の為のコンピューターシステムの不良及び第三者からの不正アクセス、コンピューターウイルスの感染等により本サービスを提供できない場合</li>
            <li>法律、法令等に基づく措置により本サービスが提供できない場合</li>
            <li>その他、当社が停止又は中断を必要と判断した場合</li>
          </ol>
          </li>
          <li>当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社はユーザーに事前に通知するものとします。但し、緊急の場合はこの限りではありません。</li>
          <li>当社は事前に電子メール等によりユーザー及び第三者に通知することなく、本サービスの内容又は名称を自由に変更できるものとします。</li>
          <li>当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</li>
        </ol>
        <br/>
        <b>第13条 （設備の負担、利用環境）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。</li>
          <li>ユーザーは、自己の本サービスの利用環境に応じて、コンピューターウイルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティー対策を自らの費用と責任において講じるものとします。</li>
          <li>ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等をユーザーのコンピューター等にインストールする場合には、ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はユーザーに発生したかかる損害について一切責任を負わないものとします。</li>
        </ol>
        <br/>
        <b>第14条 (有効期間)</b><br/>
        <div class="first-letter">
        利用契約は、ユーザーについて第4条に基づく本サービスの利用が開始された日に効力を生じ、所定の第三者の当該ユーザーの利用登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社とユーザーとの間で有効に存続するものとします。
        </div>
        <br/>
        <br/>
        <b>第15条 (規約改訂)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>当社は、随時本規約を改訂することができるものとします。</li>
          <li>当社は、本規約を改訂しようとする場合、電子メール又は本サービスに関するウェブサイト等を使い随時、ユーザーに告知するものとします。</li>
          <li>前項に基づき、本規約改訂を告知した日から当社が定める期間（定めがない場合は告知の日から1週間）以内にユーザーが自ら利用停止しない場合、当該ユーザーは本規約の改訂に同意したものとみなされ、当該ユーザーと当社との間で改訂後の規約の効力が発生するものとします。</li>
          <li>ユーザーは、前項に定める効力発生の時点以降、当該内容の不知又は不承諾を申し立てることはできないものとします。</li>
        </ol>
        <br/>
        <b>第16条 (準拠法)</b><br/>
        <div class="first-letter">
            本規約は日本国法に準じて解釈されるものとします。
        </div>
        <br/>
        <br/>
        <b>第17条 (協議・管轄裁判所)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>当社、本サービスに関連してユーザー、当社、第三者との間で疑義、問題が生じた場合、都度誠意をもって協議し、解決を図るものとします。</li>
          <li>前項の協議によっても疑義、問題が解決しない場合、東京地方裁判所を第１審の専属的合意管轄裁判所とするものとします。</li>
        </ol>
        <br/>
        <b>第18条 (連絡先)</b><br/>
        <div class="first-letter">
        本サービスに関するご意見、ご質問、苦情の申出その他利用者情報の取扱いに関するお問い合わせは、下記窓口までお願い致します。
        <br/>
        <br/>
        InfiCreo Zrt.<br/>
        address: H-8200 Veszprém, Viola utca 2., Hungary<br/>
        registry number: 19-10-500392<br/>
        email: info@anicana.org
        </div>
        <br/>
        <br/>
        <b>第19条 (一般条項)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>これらの条件は、書面で作成されたものとは見なされず、アニカナテュラーを通じてファイルおよび保存されるものとします。ユーザーは、アニカナテュラーのプラットフォームを介して、本規約の以前の各バージョン（存在する場合）にアクセスできます。</li>
          <li>本規約、個別規則、または本サービスおよびアニカナテュラーに関して当社が使用するその他の規約は、日本語によるものとします。</li>
          <li>当社は、本サービスまたはアニカナテュラーのプラットフォームに関するユーザーとの契約上の取り決めに、慣習または慣行を適用することを明示的に除外します。</li>
        </ol>
        <br/>
        <div class="text-center">* * *</div>
        </div>`
    },
    cookiePolicy: {
        mainTitle: `<div>クッキーポリシー</div>`,
        notifyParagraph: `
        <div>
          <p>最終更新日: 2023 年 1 月 24 日</p>
          <p>本クッキーポリシーでは、クッキーとは何か、およびクッキーをどのように使用するかについて説明します。または、当社が使用するクッキーの種類、クッキーを使用して収集する情報、およびその情報がどのように使用されるかを理解させるものでもあります。お手数ですが、よくお読みください。</p>
          <p>なお、日本語により記載されている本クッキーポリシーは、英文によるクッキーポリシーを訳したものであり、解釈、ニュアンス等が異なる場合がありますので、正式には英文によるクッキーポリシーを参照してください。</p>
          <p>クッキーには通常、ユーザーを個人的に識別する情報は含まれません。ただし、クッキーに関連付けられた一部の情報 (IP アドレスなど) が個人情報として認められる場合があります。
          </p>
          <p>メールアドレス、アカウントのパスワードなどの機密性の高い個人情報は、当社が使用するクッキーに保存しません。</p>
          <br/>
          <h4>定義</h4>
          <ul>
              <li>「当社」とは、InfiCreo Zrt., 8200 Veszprém Viola utca 2. Hungary を指します。 登録番号：19-10-500392</li>
              <li>「クッキー」とは、ウェブサイトによってユーザーのコンピューター、モバイルデバイス、またはその他のデバイスに保存されるテキストファイルを意味し、他の機能の中でも特にウェブサイトでの閲覧履歴の詳細が含まれているものです。</li>
              <li>「ウェブサイト」は ANICANA ΘΥΡΑ（アニカナテュラー）を指し、<a>https://anicana.org/</a> からアクセスできます。</li>
              <li>「ユーザー」とは個人また法人を代表して利用する個人として、ウェブサイトにアクセスまたは使用する個人を意味します。</li>
              <li>「セッションクッキー」はウェブサイトが訪問者のデバイスに一時的にインストールする単純なテキストファイルです。ウェブサイトでのユーザーのアクティビティのリアルタイム変化を追跡するのに役立ちます。</li>
              <li>「機能性クッキー」はウェブサイトがユーザーのサイト設定、ユーザー名、地域、言語など、サイトで行った選択を記憶できるようにするものです。これにより、ウェブサイトはパーソナライズされた機能を提供できるようになります。
              </li>
              <li>「ファーストパーティクッキー」はユーザーがアクセスするウェブサイト (またはドメイン) によって直接保存されるクッキーを意味します。</li>
          </ul>
          <br/>

          <h4>利用目的</h4>
          <p>当社は以下の目的でセッションと機能性クッキーの両方を使用します。</p>
          <div class="overflow-auto">
            {0}
          </div>
          <br/>
          <h4>クッキー使用の法的根拠</h4>
          <p>クッキーの使用が個人情報 (IP アドレスなど) の処理を伴う場合、当社のデータ処理の法的根拠は次のとおりです。</p>
          <p>ウェブサイトが適切に機能するために必要不可欠なクッキーの法的根拠は、ウェブサイトを運営し、ユーザーが適切に利用できるようにするという当社の正当な目的になります。
          </p>
          <p>必要不可欠ではないがオプションであるクッキーの場合、その法的根拠は、そのような クッキーを受け入れることを選択することによって与えられる明示的な同意です。</p>
          <br/>
          <h4>クッキーに関するユーザーの選択</h4>
          <p>ウェブサイトでのクッキーの使用を避けたい場合は、まずブラウザでクッキーの使用を無効にしてから、ウェブサイトに関連付けられているブラウザに保存されているクッキーを削除する必要があります。</p>
          <p>このオプションを使用して、クッキーの使用をいつでも防止できます。</p>
          <p>当社のクッキーを受け入れない場合、ウェブサイトの使用に不便を感じることや一部の機能が正しく機能しない場合があります。</p>
          <p>クッキーを削除したい場合、またはウェブブラウザにクッキーの削除または拒否を指示したい場合は、ウェブブラウザのヘルプページにアクセスしてください。
          </p>
          <p class="ms-5 text-start">
            Chromeウェブブラウザーの場合は、Googleの次のページにアクセスしてください。<a>https://support.google.com/accounts/answer/32050</a>
            <br/> <br/>
            Internet Explorerウェブブラウザーについては、Microsoftの次のページにアクセスしてください。
            <a>http://support.microsoft.com/kb/278835</a><br/> <br/>
            Microsoft Edgeウェブ ブラウザーについては、Microsoftの次のページにアクセスしてください。
            <a>https://support.microsoft.com/en-us/microsoft-edge/delete-クッキーs-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Select%20Settings%20%3E%20Privacy%2C%20search%2C,and%20then%20select%20Clear%20now</a>. <br/> <br/>
            Firefoxウェブ ブラウザーの場合は、Mozillaの次のページにアクセスしてください。
            <a>https://support.mozilla.org/en-US/kb/delete-クッキーs-remove-info-ウェブsites-stored</a> <br/> <br/>
            Safariウェブブラウザーについては、Appleの次のページにアクセスしてください。
            <a>https://support.apple.com/guide/safari/manage-クッキーs-and-ウェブsite-data-sfri11471/mac</a>
          </p>
          <p>その他のウェブブラウザについては、ウェブブラウザの公式ウェブページにアクセスしてください。</p>
          <br/>

          <h4>お問い合わせ</h4>
          <p>本クッキーポリシーについてご不明な点がございましたら、次のアドレスまでお問い合わせください。</p>
          <ul>
            <li>By email: info@anicana.org</li>
          </ul>
        </div>`,
        policyTablePC: `
        <table class="table table-bordered text-white cookies-table">
                <thead>
                    <tr>
                        <th>種類</th>
                        <th>名称</th>
                        <th>目的</th>
                        <th>有効期限</th>
                        <th>提供者</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>ファーストパーティクッキー（機能性クッキー）</td>
                        <td>XSRF-TOKEN</td>
                        <td>クロスサイトリクエストフォージェリ (CSRF) 攻撃から保護し、ユーザーアカウントの不正使用を防止するために使用されます</td>
                        <td>100 日</td>
                        <td>InfiCreo Zrt.</td>
                    </tr>
                    <tr>
                        <td>ファーストパーティクッキー（セッションクッキー）</td>
                        <td>anicana_session</td>
                        <td>ログインの詳細、言語設定、ウェブサイトを使用する時に行った設定の選択などを記憶するために使用されます。これらのクッキーの目的は、ユーザーにより個人的な体験を提供し、ウェブサイトを使用するたびに設定を再入力する必要をなくし、ユーザーを認証することです。</td>
                        <td>100 日</td>
                        <td>InfiCreo Zrt.</td>
                    </tr>
                </tbody>
            </table>`,
        policyTableSP: `
        <table class="table table-bordered text-white cookies-table-sp m-b-40">
              <tr>
                <td>種類</td>
                <td>ファーストパーティクッキー（機能性クッキー）</td>
              </tr>
              <tr>
                <td>名称</td>
                <td>XSRF-TOKEN</td>
              </tr>
              <tr>
                <td>目的</td>
                <td>クロスサイトリクエストフォージェリ (CSRF) 攻撃から保護し、ユーザーアカウントの不正使用を防止するために使用されます。</td>
              </tr>
              <tr>
                <td>有効期限</td>
                <td>100 日</td>
              </tr>
              <tr>
                <td>提供者</td>
                <td>InfiCreo Zrt.</td>
              </tr>
            </table>
            <table class="table table-bordered text-white cookies-table-sp">
              <tr>
                <td>種類</td>
                <td>ファーストパーティクッキー（セッションクッキー）</td>
              </tr>
              <tr>
                <td>名称</td>
                <td>anicana_session</td>
              </tr>
              <tr>
                <td>目的</td>
                <td>ログインの詳細、言語設定、ウェブサイトを使用する時に行った設定の選択などを記憶するために使用されます。これらのクッキーの目的は、ユーザーにより個人的な体験を提供し、ウェブサイトを使用するたびに設定を再入力する必要をなくし、ユーザーを認証することです。</td>
              </tr>
              <tr>
                <td>有効期限</td>
                <td>100 日</td>
              </tr>
              <tr>
                <td>提供者</td>
                <td>InfiCreo Zrt.</td>
              </tr>
            </table>`
    },
    cookiePolicyPopup: {
        title: 'クッキーが使用されます',
        body: 'サイト体験の向上ため、クッキーが使用されます。クッキーを受け入れない場合、一部の機能が正しく機能しない場合があります。'
    },
    profileIcon: {
        notifyParagraph: `プロフィール画像を設定してください
        \n
        今設定したくない場合は、後ほど「プロファイル」メニューで設定可能です`,
    },
    delectAccount: {
        mainTitle: 'delete account',
        checkboxCondition: '条件をチェックしてください',
        condition1: '退会に伴い、情報の提供を受ける権利、サービス内で取得した会員としての一切の権利を失うものとします。但し、会員サービスのシステムの都合上、会員による退会手続きが完了してから、その 手続きがシステム上反映されるまで当社所定の時間が必要な場合があり、会員はこれを承諾するものとします。',
        condition2: '本サービスの利用上の処理中に退会手続きを開始した場合、処理の結果を確認できなくなるおそれがありますので、サービス利用上の処理が完了してから退会処理を行うようにしてください。',
        condition3: '利用者が退会した時点をもって、利用契約は終了するものとします。',
        checkboxPoints: '注意事項',
        point1: '退会を決定すると、即時にユーザーデータが消去されます。一度消去したユーザーデータはいかなる場合も元に戻すことはできません。',
        point2: '退会をキャンセルすることはできません。',
        point3: '退会により、ANICANA θupaを経由したANICANA Walletの参照はできなくなります。',
        labelReason: '退会理由'
    },
    walletSetting: {
        mainTitle: 'Wallet Setting',
        labelStatus: 'ステータス',
        connectStatus: '接続済み',
        unconnectStatus: '未接続',
    },
    setting: {
        mainTitle: 'setting',
        labelVersion: 'バージョン',
        labelLanguage: '言語',
        labelTimeZone: 'タイムゾーン',
        messageNotify: '設定を変更してもよろしいですか?',
    },
    changeMobileForm: {
        headerTitle: 'profile',
        mainTitle: '携帯電話番号の変更',
        labelTitle: '新しい携帯電話番号を登録してください',
        labelNewMobileNumber: '携帯番号',
        labelConfirmMobileNumber: '携帯番号確認',
    },
    mobileVerifyForm: {
        headerTitle: 'profile',
        mainTitle: '携帯電話番号の変更',
        labelTitle: '認証コード',
        lableNotify: '新しい携帯電話番号に送信された認証コードを入力してください',
        labelNotify2: '認証コードが届かない場合',
    },
    contactUs: {
        headerTitle: 'contact',
        title: 'お問い合わせ',
        lableNotify: 'アカウントをお持ちの場合は、サインインしていただくと、より迅速に対応可能です。',
        labelName: 'Name',
        labelEmail: 'Email',
        labelQuestion: 'Question',
        already_account: 'If you have an account, please sign in so we will be able to response faster.',
    },
    changePassword: {
        headerTitle: 'password',
        mainTitle: 'パスワード変更',
        labelNewPassword: '新しいパスワード',
        labelConfirmPassword: '新しいパスワードの確認',
    },
    connectWallet: {
        headerTitle: 'connect wallet',
        paragraph: 'ANICANA Portalのさまざまなサービスを使用してアクセスするには、ANICANA Walletを接続する必要があります',
        question: 'ANICANA Walletをお持ちでないですか？',
        labelGet: 'Get One!',
    },
    createArcanaGen: {
        headerTitle: 'アルカナジェネレーター',
        labelSetArcanaName: 'アルカナ名を設定してください',
        labelCreatorName: 'クリエイター名を設定してください',
        labelSetImages: 'メイン画像を設定してください',
        labelUploadRestriction: 'ファイルアップロードの制限について',
        descUploadRestriction: '対応している画像ファイル形式はPNG、JPG、GIFです。ファイルのサイズ上限は20MBです。',
        labelWalletAddress: 'ウォレットアドレスを設定してください',
        labelSetPersona: 'ペルソナを設定してください',
        labelSetDescription: 'アルカナ説明⽂を設定してください',
        labelSetMana: 'MANAの設定',
        labelManaAddress: 'MANA アドレス',
        descManaAddress: '※MANAアドレスはコピーして、保存してください。 アルカナ生成中にエラーが発生した場合、アルカナを回復するために使用できます。',
        labelSymbol: 'シンボル',
        descSymbol: '※Symbol は MANAアドレスで⾃動割当',
        labelSeed: 'シード値',
        labelMana: 'MANA値',
        labelManaInformation: 'MANA情報',
        labelMatrixCode: 'MATRIX コードを設定してください',
        descArcanaGen: 'アルカナジェネレーターは、設定項目を入力しないで「アルカナを生成する」ボタンを押すと、未入力項目は自動設定されて生成に進みます。'
    },
    createManaGen: {
        headerTitle: 'Recover ARCANA',
        mainTitle: 'MANA Address 認証',
        messageManaAddress: 'MANA Address を利用することで、アルカナを復活できます。自分で入力するかリストから選んでください。入力の際、アドレスに誤りなどがあるとアルカナが⽣成できなくなりますのでご注意ください。',
    },
    message: {
        messageSignOut: 'サインアウトしました',
        confirmSignOut: 'サインアウトしてもよろしいですか。',
        messageChangeMobile: '携帯電話番号を変更しました',
        messageContactUs: 'メッセージが送信されました スタッフからご連絡いたしますのでしばらくお待ちください',
        messageChangeProfileName: 'プロフィール名を変更',
    },
    buttonlist: {
        cancelBtn: '戻る',
        submitBtn: '実行',
        nextBtn: '次へ',
        connectBtn: '接続',
        sendSmsBtn: 'SMS送信',
        sendCodeBtn: 'コード送信',
        acceptBtn: 'accept',
        disconnectBtn: 'disconnect',
        backBtn: '戻る',
        saveBtn: '保存',
        resendCodeBtn: 'コードを再送する',
        okBtn: 'OK',
        sendBtn: '送る',
        signinBtn: 'サインイン',
        createArcanaBtn: 'アルカナを生成する',
        enterBtn: '次へ',
        confirmBtn: '実行',
        goToPayBtn: '支払い',
        sellBtn: '売る',
        chooseBtn: '選択',
        makeBidBtn: '買い希望をする',
        duringTrading: '取引中',
        yesBtn: 'はい',
        noBtn: 'いいえ',
        createOctillionBtn: 'Octillionアカウントの作成',
        proceedWithApp: 'アプリで決済',
        proceedWithWebsite: 'webで決済',
        setManaAddressBtn: 'MANA Address 認証',
        inputBtn: '入力',
        selectBtn: '選択',
        closeBtn: '閉じる',
        seeWalletBtn: 'ウォレット確認',
        nextpaginationBtn: '次へ >>',
        previouspaginationBtn: '<< 前へ',
        seeDetailBtn: 'もっと見る',
        more: 'もっと見る',
        clearBtn: 'クリア',
        applyBtn: '検索',
        search: '検索',
        seeCookiePolicyBtn: 'クッキーポリシーを見る',
        sortConfirmBtn: '確定',
        leviasNextBtn: '次へ',
        selectArcanaBtn: 'ARCANAを選択',
        selectPersonaBtn: 'PERSONAを選択',
        absorbBtn: '食べる',
        drawChainBtn: 'ドロー',
        drawBtn: 'ドロー',
        goToRegisterBtn: '口座登録する',
    },
    offer: {
        makeAskOffer: '売り値の設定',
        makeBidOffer: '買い値を設定',
        selectPaymentCurrency: '支払い通貨を選択',
        animaPayment: 'Anima',
        japaneseYenPayment: '日本円',
        currentBidOffer: '現在の買い値',
        myAnimaBalance: 'My ANIMA',
        AskOffer: 'Ask Offer',
        BidOffer: 'Bid Offer',
        setAskPrice: 'Set Ask Price',
        setBidPrice: 'Set Bid Price',
        confirmAskOffer: '売り希望内容の確認',
        confirmBidOffer: '買い希望内容の確認',
        itemCategory: '取引内容',
        symbolAndTokenName: 'トークン名',
        tokenId: 'トークンID',
        txCategory: '取引区分',
        price: '取引希望金額',
        providerCharge: 'プロバイダ手数料',
        notifyAskParagraph: '売りの希望に関する申し込みがアニカナチェーン上に記録され、売り手のASK IDが生成されました。オファーや取引についてはマーケットプレイス Octilionにてご確認ください。 ',
        notifyBidParagraph: '買い希望に関する申し込みが作成されました。オファーや取引についてはマーケットプレイス Octilionにてご確認ください。 ',
        cancelAskConfirm: '売りオファーを\nキャンセルしますか?',
        cancelBidConfirm: 'ビッドオファーを\nキャンセルしますか?',
        biddingPaymentInfo: '支払いを実行するためにLEVICAのアプリケーションが必要になっています。LEVICAをまだお持ちでない方は以下のアイコンをタップして、アプリケーションをダウンロードしてください。LEVICAを既にお持ちの方はそのまま支払いにお進みください。',
        createOctillionAccount: 'Create Account',
        createOctillionParagraph: 'Octillion のアカウントをまだお持ちでないようです。\n買いオファーは マーケットプレイス Octillion を介して処理されます。アカウントをお持ちでない場合は、下のボタンを使ってて登録してください。',
        nonOctillionText: 'Octillionアカウントをお持ちでない方',
        selectThirdPartyProvider: 'サードパーティプロバイダを選択して下さい',
        tapToOpenPaymentPage: 'タップして決済ペー\nジを開きます',
    },
    common: {
        yen: 'JPY',
        yenSymbol: '円',
        formattedYen: '{0}円',
        animaSymbol: 'ANM',
        formattedAnima: '{0} ANM',
        noSearchResult: 'アイテムがありません',
        noPersonaResult: 'アイテムがありません',
        copy_text: "クリップボードにコピーしました",
        langClass: "jp_lang",
    },
    // Seperately By Page Name
    accountPage: {
        Account: 'アカウント',
        Payment: '支払い',
        Settings: '設定',
        Signout: 'サインアウト',
        Contact: 'コンタクト',
        Terms_Of_Use: '利用規約',
        Privacy_Policy: 'プライバシーポリシー',
        Company: '@InfiCreo ZRT',
    },
    footerPage: {
        Contact: 'コンタクト',
        Terms_Of_Use: '利用規約',
        Privacy_Policy: 'プライバシーポリシー',
        Cookie_Policy: 'クッキーポリシー',
        Company: '@InfiCreo ZRT',
        Technical_Paper: 'テクニカルペーパー',
        White_Paper: 'ホワイトペーパー',
    },
    acountDetail: {
        headerTitle: 'Account',
        noName: 'no name',
        labelEmailAddress: 'メールアドレス',
        labelMobileNumber: '携帯電話番号変更',
        labelNoMobile: 'no mobile',
        labelSecurityCode: 'セキュリティコード',
        labelTwofactors: '2FA',
        labelPassword: 'パスワード',
        labelDeletAccount: 'アカウントを削除する',
    },
    accountDetailPage: {
        Email_Address: 'メールアドレス',
        Mobile_Number: '携帯電話番号',
        Security_Code: 'セキュリティコード',
        MFA: '2FA',
        Password: 'パスワード',
        Delete_Account: 'アカウントを削除する',
        changeProfileLabel: 'ギャラリーから画像を選択して下さい。',
        GALLERY: 'ライブラリ',
        TAKE_PHOTO: '写真を取る',
        infoSuccess: 'アカウント情報を更新しました'
    },
    changeEmailPage: {
        change_Email_Address: 'メールアドレスの変更',
        Register_your_new_email_address: '新しいメールアドレスを登録してください',
        new_email_address: '新規メールアドレス',
        confirm_email_address: '新規メールアドレスの確認',
    },
    changeEmailVerificationPage: {
        change_Email_Address: 'メールアドレスの変更',
        Verification_Code: '認証コード',
        Enter_the_verification_code_sent_to_your_new_email_address: '新しいメールアドレスに送信された認証コードを入力してください',
        Have_not_received_any_verification_Email: '認証コードが届かないですか？',
        Resend_code: 'コードを再送する',
        Your_email_address_has_been_successfully_changed: 'メールアドレスが正常に変更されました。',
        Cancel_message: 'キャンセルしてもよろしいです？\n新しいメールアドレスは登録されません'
    },
    changeMobilePage: {
        change_mobile_number: '携帯電話番号の変更',
        Register_your_new_phone_number: '新しい携帯電話番号を登録してください',
        new_mobile_number: '新規電話番号',
        confirm_mobile_number: '携帯番号の確認',
    },
    changeMobileVerificationPage: {
        change_mobile_number: '携帯電話番号の変更',
        Verification_Code: '認証コード',
        Enter_the_verification_code_sent_to_your_new_mobile_number: '新しい携帯電話番号に送信された認証コードを入力してください',
        Have_not_received_any_verification_SMS: '認証コードが届かないですか？',
        Resend_code: 'コードを再送',
        Your_mobile_number_has_been_successfully_changed: `携帯電話番号は正常に\n変更されました`,
    },
    changeNamePage: {
        Change_profile_name: 'プロフィール名を変更',
    },
    changeMFAPage: {
        You_have_enable_2FA: '2FA を有効にしました',
        You_have_disable_2FA: '2FA を無効にしました',
    },
    changePasswordPage: {
        change_Password: 'パスワード変更',
        Old_Password: '以前のパスワード',
        New_Password: '新しいパスワード',
        Confirm_Password: '新しいパスワードの確認',
        Your_password_has_been_successfully_changed: 'パスワードは正常に変更されました',
        password_had_been_reset: 'パスワードがリセットされました。再度ログインして下さい。',
    },
    deleteAccountpage: {
        Check_conditions: '条件をチェックしてください',
        Reason_for_withdrawal: '退会の利用を書いてください',
        Important_points: '注意事項',
        confirm_Modal_Title: `アカウントを削除してもよろしいですか？\n アカウントは完全に削除され、元に戻すことはできません。`,
        complete_Modal_Text: 'あなたのアカウントは正常に削除されました。',
    },
    userWalletPage: {
        Address: 'アドレス',
        Status: 'ステータス',
        Connected: '接続済み',
        Not_connected: '未接続',
        confirm_Modal_Title: 'ウォレットを切断してもよろしいですか？\n サービスを使用できなくなります。',
        complete_Modal_Text: 'ウォレットは正常に切断されました。',
    },
    settingsPage: {
        Version: 'バージョン',
        Language: '言語',
        Time_Zone: 'タイムゾーン',
        confirm_Modal_Text: '設定を変更してもよろしいですか？',
        BGM: 'BGM',
    },
    contactPage: {
        Name: 'お名前',
        Email: 'メールアドレス',
        Question: '問い合わせ内容',
        complete_Modal_Text: `メッセージが送信されました\nスタッフからご連絡いたしますのでしばらくお待ちください`,
    },
    signoutPage: {
        confirm_Modal_Text: '本当にサインアウトしますか?',
    },
    sidebarPage: {
        Home: 'ホーム',
        Topics: 'トピックス',
        Trend: 'トレンド',
        Arcanas: 'アルカナ',
        Personas: 'ペルソナ',
        NewArcana: '新着アルカナ',
        NewPersona: '新着ペルソナ',
        Games: 'プロダクト',
        News: 'ニュース',
        New: '新着',
        CodeD: 'コードディ',
        Top10: '人気',
        Setting: '設定',
        Sign_Out: 'サインアウト',
        Sign_In: 'サインイン',
        Contact: 'コンタクト',
        Technical_Paper: 'テクニカルペーパー',
        White_Paper: 'ホワイトペーパー',
        Terms_Of_Use: '利用規約',
        Privacy_Policy: 'プライバシーポリシー',
        Cookie_Policy: 'クッキーポリシー',
        Company: '@ InfiCreo ZRT',
        Square: 'スクエア'
    },
    menuPage: {
        Home: 'ホーム',
        Games: 'プロダクト',
        CodeD: 'コードディ',
        Arcana: 'アルカナ',
        Persona: 'ペルソナ',
        Square: 'スクエア',
        Support: 'サポート',
        Settings: '設定',
        Topics: 'トピックス',
        Trend: 'トレンド',
        News: 'ニュース',
    },
    walletDashboardPage: {
        Address: 'アドレス',
        Send: '送信',
        Receive: '受信',
        TX_History: 'TX 履歴',
        Wallet_Settings: 'ウォレット設定',
        Mana_Address: 'MANAアドレス',
        Absorb: '食べる',
        Draw_History: 'ドロー履歴',
        Support: 'サポート',
        ARCANA: 'MY ARCANA',
        PERSONA: 'My PERSONA',
        Coming_Soon: 'Coming soon',
        ANIMA: 'ANIMA',
        OTHERS: 'OTHERS',
        LEVICA: 'LEVICA',
        securityToken: 'Security Token',
    },
    sendAnimaPage: {
        Send_ANIMA: 'ANIMAの送信',
        Address: 'アドレス',
        ANIMA_Amount: 'ANIMAの数量',
        Available: '利用可能',
    },
    sendAnimaConfirmPage: {
        Confirmation: '最終確認',
        Are_you_sure_you_want_to_make_a_transaction_with_the_details_below: '以下の取引を実行してもよろしいですか？',
        Blockchain: 'ブロックチェーン',
        Amount: '数量',
        To: '送信先',
        complete_Modal_Text: `取引は正常に行われました。\n詳細は取引履歴にてご確認ください。`,
        nft_successful_sent: 'NFT は正常に送信されました。\n取引履歴にてご確認ください。'
    },
    loginPage: {
        Sign_in_text: 'サインイン',
        email: 'メールアドレス',
        forgot_email: 'メールアドレスをお忘れの方',
        password: 'パスワード',
        forgot_password: 'パスワードをお忘れの方',
        Sign_in_btn: 'サインイン',
        Do_not_have_LEVIAS_ID_yet: 'LEVIAS IDをまだ持っていませんか?',
        Create_account: 'アカウントを作成する',
        errorText: 'エラー',
        user_not_found: 'ユーザーが見つかりません。',
        Code_has_been_successfully_send: 'コードが正常に送信されました',
    },
    cancelRegPage: {
        cancelModalTitle: 'キャンセルします。よろしいですか？',
        cancelModalText: '登録したデータはすべて破棄されます',
        signIn: 'サインイン'
    },
    idIssuePage: {
        Create_your_LEVIAS_ID: 'LEVIAS IDの作成',
        LEVIAS_ID_is_needed_for_using_the_services_of_ANICANA_θupa_Please_check_and_agree_to_the_Terms_of_Use_and_Privacy_Policy_of_LEVIAS_below: '本サービスの利用にあたり、\n利用規約とプライバシー ポリシーに同意してください',
        Term_of_Use: '利用規約',
        Privacy_Policy: 'プライバシーポリシー',
    },
    userNamingPage: {
        Profile_Name: 'プロフィール名',
        Set_your_profile_name_which_will_be_shown_to_the_public: '公開されるプロフィール名を設定します。',
        Please_be_careful_when_entering_a_real_name_or_a_name_that_can_identify_a_specific_individual: '実名や特定の個人を識別できる名前の入力にはご注意ください。',
        You_can_edit_it_in_the_Profile_menu_later: '後で「プロフィール」メニューで編集できます。',
    },
    setProfileIconPage: {
        Profile_Icon: 'プロフィール写真',
        Set_a_profile_image_If_you_do_not_want_to_set_it_now_you_can_do_it_later_in_the_Profile_menu: 'プロフィール画像を設定してください。今設定したくない場合は、後ほど「プロファイル」メニューで設定可能です。',
        chooseImgModalText: 'ギャラリーから画像を選択するか、写真を撮ってください',
    },
    emailAddressSettingsPage: {
        Set_Email_Address: 'メールアドレス登録',
        Enter_an_email_address_for_your_account_It_will_be_used_as_your_contact_address: 'アカウントに登録するメールアドレスを入力してください。ログイン用のユーザー名として使用されます',
    },
    enterVerificationCodePage: {
        verification_code: '認証コード',
        Enter_the_verification_code_sent_to_your_registered_email_address: 'ご登録のメールアドレスに送信された認証コードを入力してください',
        Have_not_received_any_verification_email: '認証コードが届いてないですか?',
        Resend_code: 'コードを再送する',
    },
    twoFactorVerificationPage: {
        two_factor_authentication: '2段階認証',
        new_mobile_number: '携帯番号',
        confirm_mobile_number: '携帯番号の確認',
        Register_your_phone_number_for_two_factor_authentication: '二段階認証用の携帯電話番号を登録してください',
    },
    mobileVerificationCodePage: {
        verification_code: '認証コード確認',
        Enter_the_verification_code_sent_to_your_registered_mobile_number: 'ご登録の携帯電話に送信された認証コードを入力してください。',
        Have_not_received_any_verification_SMS: '確認SMSを受信していませんか?',
        Resend_code: 'コードを再送する',
    },
    passwordSetPage: {
        set_password: 'パスワードを設定してください',
        new_password: 'パスワード',
        confirm_password: 'パスワード確認',
        Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters_at_least_one_digit_and_one_special_character: 'パスワードは8～32文字で設定してください。 半角英数字を使用し、必ず大文字、小文字、数字を含めてください。特殊文字を使用しないでください。',
        Your_LEVIAS_ID_has_been_successfully_created: 'アカウントが正常に作成されました。',
        Please_sign_in: 'ご登録のメールアドレスとパスワードでサインインしてください。',
        SIGN_IN: 'サインイン',
        CONFIRM: '確認',
    },
    passwordResetPage: {
        Password_Reset: 'パスワードのリセット',
        email: 'メール',
        mobile_number: '携帯電話番号',
        Please_enter_you_registered_email_address_and_phone_number_An_SMS_will_be_sent_with_the_verification_number: '登録したメールアドレスと電話番号を入力してください。 確認番号が記載されたSMSが送信されます。',
        SEND_SMS: 'SMSを送信',
    },
    passwordResetOTPPage: {
        Verification_Code: '確認コード',
        Enter_the_verification_code_sent_to_your_registered_mobile_number: '登録した携帯電話番号に送信された確認コードを入力します。',
        Have_not_received_any_verification_SMS: '確認SMSを受信していませんか?',
        Resend_code: 'コードを再送する',
    },
    passwordResetFormPage: {
        Password_reset: 'パスワードのリセット',
        new_password: '新しいパスワード',
        confirm_new_password: '新しいパスワードの確認',
        Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters: 'パスワードは8～32文字で設定してください。 半角英数字を使用し、必ず大文字、小文字、数字を含めてください。特殊文字を使用しないでください。',
        CONFIRM: '確認',
    },
    forgotEmailPage: {
        Forgot_Email: 'メールアドレスを忘れた方',
        mobile_number: '携帯電話番号',
        password: 'パスワード',
        Please_enter_you_registered_phone_number_and_password_An_SMS_will_be_sent_with_with_your_registered_email_address: '登録した電話番号とパスワードを入力してください。 登録したメールアドレスにSMSが届きます。',
        SEND_SMS: 'SMSを送信',
        confirm_Modal_Title: '登録した携帯電話にSMSが送信されました。',
        complete_Modal_Text: 'SMSでメールアドレスを確認し、サインインしてください。',
    },
    authContext: {
        walletConnectError: "接続に失敗しました。リロードして再度試して下さい。",
        otpCodeMessage: "メールに送信されたワンタイムパスワードを確認して下さい。"
    },
    leviasFooter: {
        LEVIAS_Inc_All_rights_reserved: '© LEVIAS Inc. All rights reserved.',
    },
    tokenList: {
        headerTitle: 'ARCANAs',
        Persona: 'PERSONAs',
        filter: 'フィルタ',
        sort: '並べ替え'
    },
    topicList: {
        headerTitle: 'Topics',
    },
    newsList: {
        headerTitle: 'news',
        all: '全て'
    },
    trendList: {
        headerTitle: 'Trend',
    },
    productList: {
        headerTitle: 'Products',
        new: '新着プロダクト',
        rating: '評価',
        age: '年齢',
        lang: '言語',
        category: 'カテゴリ',
        provider: 'デベロッパー',
    },
    squareList: {
        headerTitle: 'Square',
        subscribed: '登録完了',
        unsubscribed:'登録解除',
        subscribedSuccess: 'スクエアが正常に登録されました。',
        unsubscribeSuccess:'スクエアが正常に解除されました。',
        newSquare:'新着スクエア'
    },
    squareDetail:{
        subscribe:'スクエア登録',
        unsubscribe:'登録解除',
        subscribers:'名登録',
        drawChain:'ドローチェーン',
        all:'ホーム',
        games:'ゲーム',
        options:'オプション',
        news:"ニュース",
        popularGames:"人気ゲーム",
        drawChains:"ドローチェーン対象",
        personas:"ペルソナ",
        adSquare:"アドスクエア",
        createArcana:"アルカナ生成",
        createArcanaDialog:{
            title:"アルカナを作る",
            okButton:"アルカナ生成",
            cancelButton:"キャンセル",
        },
        squarePoints:"スクエアポイント",
        squarePointAlert:"{0}のADEXを全て消費して、アルカナジェネレータを呼び出します。宜しいですか？",
        registerSquare:"ドローチェーンのご利用にはスクエア登録が必要になりますので、スクエア登録をしてください。",
        confirmUnsubscribe:"スクエア登録を解除してよろしいですか？",
        footer:{
            contact:'コンタクト',
            termsOfUse:'利用規約',
            privacyPolicy:'プライバシーポリシー',
            contactDescription:'本スクエアに対するお問い合わせは下記メールアドレス宛にお送りください。',
            lawNotation:'特定商取引法表記',
        }
    },
    drawChainList: {
        headerTitle: 'Draw Chain',
        persona:'ドロー対象ペルソナ'
    },
    drawChainComparison:{
        drawSuccessMsg: 'ドロー チェーンが正常に完了しました。',
        drawFailMsg: 'Ooops...\nSomething went wrong.\nPlease check the PERSONA condition and try again.',
        necessaryPersona: 'ドロー対象のペルソナ',
        yourPersona: 'マイペルソナ',
        reselectPersona:'ペルソナ再選択'
    },
    anicana: {
        headerTitle: 'anicana'
    },
    filter: {
        headerTitle: 'フィルタ',
        category: 'Category',
        arcana: 'ARCANA',
        persona: 'PERSONA',
        basics: 'Basics',
        scores: 'Scores',
        saleStatus: 'ASK Status',
        underSale: 'ASK (日本円）',
        scoreMaxWarning: 'スコアは {0} まで設定できます'
    },
    sort: {
        headerTitle: '並び替え',
        Newest: '新しい順',
        Oldest: '古い順',
        done: '検索'
    },
    tokenPage: {
        My_Wallet: 'My Wallet',
        My_Tokens: 'マイトークン',
        ADD: '追加',
        Are_you_sure_you_want_to_delete_〇〇_Token: 'を消去してもよろしいですか',
        〇〇_Token_has_been_successfully_deleted: 'トークンが正常に削除されました。',
    },
    tokenAddPage: {
        My_Wallet: 'My Wallet',
        Add_Token: 'トークンを追加',
        Token_Name: 'トークン名',
        Token_Address: 'トークンアドレス',
        Token_Unit: 'トークンユニット',
        Token_Price: 'トークン価格',
        cancel: 'キャンセル',
        add: '追加',
        Send: '送信',
        Are_you_sure_you_want_to_cancel: 'トークン追加を中止します。よろしいですか？',
        〇〇_Token_has_been_successfully_added_to_your_wallet: 'トークンがウォレットに正常に追加されました。',
    },
    tokenDetailPage: {
        My_Wallet: 'My Wallet',
        TKN: 'TKN',
        Send: '送信',
        Receive: '受信',
        Delete: '削除',
        Received: '受け取る',
        Sent: '送信',
        Pending: 'Pending',
        transaction: 'Transactions',
        TX_ID: 'TX ID',
        From: 'From',
        Are_you_sure_you_want_to_delete_〇〇_Token: '削除してもよろしいですか',
        〇〇_Token_has_been_successfully_deleted: 'トークンが正常に削除されました。',
    },
    tokenSendPage: {
        My_Wallet: 'My Wallet',
        Send_〇〇: '送信〇〇',
        Address: '住所',
        long_press_to_paste: '貼り付け',
        read_QR_code: 'QRコードを読み取る',
        Amount: '数量',
        enter_amount: '数量を入力',
        cancel: 'キャンセル',
        next: '次へ',
        Are_you_sure_you_want_to_cancel_sending: '送信をキャンセルしてもよろしいですか？',
    },
    tokenReceivePage: {
        My_Wallet: 'My Wallet',
        My_Address: '私のアドレス',
        My_wallet_QR_code: '私の財布のQRコード',
    },
    tokenSendConfirmationPage: {
        My_Walet: 'My Walet',
        Confirmation: '確認',
        Please_confirm_the_transaction_details_below: '下記お取引内容をご確認ください。',
        Token_Name: 'トークン名',
        Blockchain: 'ブロックチェーン',
        Amount: '数量',
        To: 'に',
        cancel: 'キャンセル',
        submit: '実行',
        Are_you_sure_you_want_to_cancel_sending: '送信をキャンセルしてもよろしいですか？',
        The_Transaction_has_been_successfully_requested: 'トランザクションは正常にリクエストされました。',
    },
    loginLeviasIdMsgModal: {
        LEVIAS_ID: 'LEVIAS ID',
        For_using_the_services_of_ANICANA_θupa_you_need_your_LEVIAS_ID_provided_by_LEVIAS_INC: 'ANICANA Walletを認証するための LEVIAS IDが必要です。',
        Do_not_show_this_again: '2回目は表示しない',
        NEXT: '次へ',
    },
    leviasBottomModal: {
        CANCEL: 'キャンセル',
        BACK: '戻る',
        GALLERY: 'ライブラリ',
        TAKE_PHOTO: '写真を取る',
        ENTER_EMAIL: 'メール再送信',
        ENTER_CODE: 'コード再送信',
        ENTER_MOBILE: 'モバイル再送信',
        code_resend: 'コードを再送する',
    },
    walletArcanaDetailPage: {
        Name: 'Name',
        Element: 'Element',
        Green_Star: 'Green Star',
        Bloodline: 'Bloodline',
        Birthday: 'Birthday',
        FOR_Force: 'FOR（エネルギー）',
        ABS_Abyss: 'ABS（深淵）',
        DFT_Determination: 'DFT（意思）',
        MND_Mind: 'MND（精神）',
        INT_Intelligence: 'INT（知識）',
        EXP_Experience: 'EXP（経験値）',
        Peerless_Steel: '無双の鋼',
        Golden_Light: '黄金の光',
        Unfathomable_Abyss: '底知れぬ深淵',
        Adamantine_Rock: '堅牢な岩',
        Crimson_Flame: '紅蓮の焔',
        Eternal_Tree: '常磐なる樹',
        Heavenly_Dew: '天の雫',
        ASK: 'ASK',
        BID: 'BID',
        Token_Id: 'Token Id',
        Created_By: 'Created By',
        Contract_Address: 'Contract Address',
        Blockchain: 'Blockchain',
        Token_Standard: 'Token Standard',
        MX_Creator_Bonus: 'MX Creator Bonus',
        anima: 'Anima'
    },
    bottomNav: {
        home: 'ホーム',
        square: 'スクエア',
        games: 'プロダクト',
        menu: '検索',
        news: 'ニュース',
    },
    review: {
        feedbackTitle: '評価ありがとうございます'
    },
    codeD: {
        message: "お手持ちのコード(8桁の英数字)を入力してください。有効なコードが入力されると、アルカナジェネレーターが起動します。",
        whatsCodeD: "コードディとは？",
        codeDInput: 'コードディ入力',
        aboutTitle: "コードディについて",
        about: "コードディ(Code Dimension)とは、有効な8桁の英数字を入力すると、設定されたアルカナジェネレーターを起動することができるインターフェースです。\n" +
            "起動したアルカナジェネレーターを使って、ユーザー独自のアルカナを生成することができます。\n" +
            "\n" +
            "コードディに関するご質問等につきましては、下記のメールアドレスまでお問い合わせください。",
        popupText: "LEVIAS ID でサインイン(ウォレット認証)してください。まだアニカナウォレットをお待ちでない方やアニカナをはじめてご利用になられる方は、新規LEVIAS ID を作成してください。※Web3Auth でプライベートキーを生成します。",
        aleradyUsed: "無効なコードです",
    },
    chainDetailPage: {
        FOR_Force: 'FOR',
        ABS_Abyss: 'ABS',
        DFT_Determination: 'DFT',
        MND_Mind: 'MND',
        INT_Intelligence: 'INT',
        EXP_Experience: 'EXP',
    }     
}
