import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { LeviasSwalEmail, LeviasSwalWrong } from "../components/common/Swal";
import { fetchCustomData, getData, postData } from "../services/fetch";
import { clearLocalStorage, getToken, getValue, getWalletAddress, setValue, setWalletAddress } from "../services/storage";
import { Web3authContext } from "./web3authContext";
import { setToken, setWeb3authToken } from "../services/storage";
import { fetchData } from "../services/fetch";
import { appContext } from "./appContext";
import { strings, translations } from "../services/localization";
import { REACT_APP_CODE_D_MEASUREMENT_API_URL } from "../config";
import { isObjectEmpty } from "../components/common/commonFnc";
import { useLocation } from "react-router-dom";

export const AuthContext = createContext(); // could have a default value

export const AuthProvider = ({ children }) => {
    const { web3auth, logout: web3auth_logout } = useContext(Web3authContext);
    const { language, timeZone, updateTimeZone, updateLang } = useContext(appContext);
    const [user, setUser] = useState({});
    const [userIdms, setUserIdms] = useState({})

    const location = useLocation();
    const search = location.search;
    const query = new URLSearchParams(search);
    const isLogout = query.get('logout') === 'true';
    const r_server = query.get('r_server') || '1'; // Portal = 1 (default)

    const authContext = translations.authContext

    const loadUser = async () => {
        const token = await getToken();
        if (token) {
            const user = await getValue("user_info");
            if (
                user &&
                !isObjectEmpty(user)
            ) {
                const jsonUser = user ? JSON.parse(user) : null;
                setUser(jsonUser);
            } else {
                // UpdateUser();
            }
        }
    };

    const UpdateUser = async () => {
        if (!isLogout) {
            var response = await fetchData(`account/update`, { r_server, time_zone: "Asia/Tokyo" }, 'post', false);
            if (response.ok) {
                setValue('user_info', response.data.user)
                setUser(response.data.user);
                setUserIdms(response.data.user_idms);
            }
        }
    }

    // const loadUser = async () => {
    //     const token = await getToken()
    //     if (token) {
    //         var response = await fetchData(`user/update`, {}, 'post', false);
    //         setValue('user_info', response.data.user)
    //         setUser(response.data.user);
    //         setUserIdms(response.data.user_idms);
    //     }
    //     else {
    //         const user = await getValue('user_info');
    //         setUser(user)
    //     }
    // }

    useEffect(() => {
        loadUser();

        return () => {
            setUser({})
        }
    }, [])

    // useEffect(() => {
    //     setValue('user_info', user);
    // }, [user])

    const login2FA = async (email, setLoading, setIdmsRegistrationStep, isIframe = false, idms_id = "") => {
        var response = await fetchData(`otp/generate`, {
            email, idms_id
        }, 'post', false);
        if (response.ok) {
            if (response.data) {
                if (response.data.errorMessage) {
                    setLoading(false);
                }
                if (response.data.verificationcode) {
                    LeviasSwalEmail.fire({
                        title: strings.formatString(authContext.otpCodeMessage)
                    }).then((result) => {
                        setLoading(false)
                        setIdmsRegistrationStep(isIframe ? 'emailVerifyIframe' : 'emailVerify')
                    })
                }
            }
        } else {
            setLoading(false);
            LeviasSwalWrong.fire({
                title: "Error",
                text: response.data.message,
            });
        }
        return response;
    }


    const login = async (email, password, r_server = "1", setLoading, setIdmsRegistrationStep, needWeb3authLogin = true, redirect_uri = null, redirect_data = null, isIframe = false) => {
        setLoading(true)
        let res = { id_token: '', need2FA: false };
        var response = await fetchData(`login`, {
            email,
            password,
            r_server
        }, 'post', true);
        if (response.ok) {
            if (response.data) {
                if (response.data.access_token) {
                    // dispatch(authAction.login(response.data.user));
                    clearLocalStorage();
                    // setWallet_address(response.data.wallet_address || '');
                    if (web3auth && needWeb3authLogin) {
                        web3auth_logout()
                    }
                    setToken(response.data.access_token)
                    setWeb3authToken(response.data.web3auth_token)
                    if (!needWeb3authLogin) {
                        console.log("NO NEED WEB3 LOGIN")
                        console.log({ res })
                        return res
                    }
                    setValue('user_info', response.data.user)
                    setUser(response.data.user)
                    res.id_token = response.data.web3auth_token;
                    return res;
                }
                else if (response.data.user?.twofa === 1) {
                    setUser(response.data.user)
                    setValue('user_info', response.data.user)
                    setWeb3authToken(response.data.web3auth_token)
                    // web3auth.setWallet_address(response.data.wallet_address || '')
                    await login2FA(email, setLoading, setIdmsRegistrationStep, isIframe, response.data.user.idms_id)
                    res.need2FA = true;
                    return res;
                }
                else if (response.data.errorMessage) {
                    setLoading(false);
                    let message = response.data.errorMessage;
                    LeviasSwalWrong.fire({
                        title: "Error",
                        text: message,
                    });
                } else if (response.data.errors) {
                    setLoading(false);
                    let message = response.data.message;
                    LeviasSwalWrong.fire({
                        title: "Error",
                        text: message,
                    });
                }
                else if (response.data.message) {
                    setLoading(false);
                    let message = response.data.message;
                    LeviasSwalWrong.fire({
                        title: "Error",
                        text: message,
                    });
                    res.id_token = null;
                }
            }
            return res;
        } else {
            setLoading(false);
            LeviasSwalWrong.fire({
                title: "Error",
                text: response.data.message,
            });
            return false
        }
    }

    const logout = async (jump_to_login_page = true) => {
        setUser({});
        web3auth_logout();
        const prev_language = language;
        const prev_timezone = timeZone;
        clearLocalStorage();
        // localStorage.setItem(posthog.opt_in_capturing())
        if (prev_language || prev_timezone) {
            console.log({ prev_language, prev_timezone })
            updateLang('');
            updateTimeZone('');
            if (!jump_to_login_page) {
                return
            }
            setTimeout(() => {
                if (prev_language) {
                    updateLang(prev_language);
                }
                if (prev_timezone) {
                    updateTimeZone(prev_timezone);
                }
                window.location.href = '/';
            }, 500);
        }
        else {
            if (jump_to_login_page) {
                window.location.href = '/';
            }
        }
    }

    const updateUserInfo = async () => {
        const response = await getData(`user/info`);
        if (response.ok) {
            const old_wallet = getWalletAddress();
            const new_wallet = response.data.wallet_address;
            console.log("OLD:" + old_wallet)
            console.log("NEW:" + new_wallet)
            setWalletAddress(new_wallet)
            setUser(response.data.user);
            /*(if (old_wallet != new_wallet) {
                LeviasSwalWrong.fire({
                    text: 'Wallet address is different. Please login again.',
                })
                logout();
            } else {
                setUser(response.data.user);
            }*/
        }
    }

    const updateCodeDMeasurement = async (code_d_measurement_id, key) => {
        await fetchCustomData(REACT_APP_CODE_D_MEASUREMENT_API_URL + 'code-d-measurements?id=' + code_d_measurement_id, { [key]: 1 }, 'PUT');
    }

    const value = useMemo(() => ({
        user, userIdms, setUser, login, logout, updateUserInfo, login2FA, updateCodeDMeasurement
    }), [user]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
