import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import RoutePath from './routes/route'
import './App.css'
import './style/app/app.scss';
import { Web3authProvider } from "./context/web3authContext";
import { AuthProvider } from "./context/authContext";
import { AppProvider } from "./context/appContext";
// import { usePageViews } from "./hooks/usePageViews";
import useGTMPageView from "./hooks/useGTMPageView";

function App() {
  // usePageViews(); // Track page views
  useGTMPageView();
  return (
    <div className="App">
      <AppProvider>
        <Web3authProvider>
          <AuthProvider>
            <RoutePath />
          </AuthProvider>
        </Web3authProvider>
        {/* {
          posthog.has_opted_out_capturing() || posthog.has_opted_in_capturing() || isLevias || loginPage
            ? null
            : <CookieBanner isLevias={true} />
        } */}
      </AppProvider>
    </div>
  )
}

export default App;
