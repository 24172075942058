import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useGTMPageView = () => {
    const location = useLocation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "pageview",
            page: location.pathname,
        });
        // console.log("GTM Pageview Event:", location.pathname); // For debugging
    }, [location]);
};

export default useGTMPageView;