// Localhost Api Domain
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
const AWS_S3_BUCKET_DIRECTORY = process.env.REACT_APP_AWS_S3_BUCKET_DIRECTORY
const AWS_S3_ENDPOINT = process.env.REACT_APP_AWS_S3_ENDPOINT
const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-1KYGN269TR';
const REACT_APP_GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const REACT_APP_BLOCKCHAIN_API_URL = process.env.REACT_APP_BLOCKCHAIN_API_URL
const REACT_APP_WALLET_CLIENT_ID = process.env.REACT_APP_WALLET_CLIENT_ID
const REACT_APP_RPC_TARGET = process.env.REACT_APP_RPC_TARGET
const REACT_APP_BLOCK_EXPLORER = process.env.REACT_APP_BLOCK_EXPLORER
const REACT_APP_CHAIN_ID = process.env.REACT_APP_CHAIN_ID
const REACT_APP_INCUBATOR_CONTRACT_ADDRESS = process.env.REACT_APP_INCUBATOR_CONTRACT_ADDRESS
const REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS = process.env.REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS
const REACT_APP_ARCANA_CONTRACT_ADDRESS = process.env.REACT_APP_ARCANA_CONTRACT_ADDRESS
const REACT_APP_PERSONA_CONTRACT_ADDRESS = process.env.REACT_APP_PERSONA_CONTRACT_ADDRESS
const REACT_APP_ANIMA_CONTRACT_ADDRESS = process.env.REACT_APP_ANIMA_CONTRACT_ADDRESS
const REACT_APP_TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS
const REACT_APP_EGG_CONTRACT_ADDRESS = process.env.REACT_APP_EGG_CONTRACT_ADDRESS
const REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS = process.env.REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS
const REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS = process.env.REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS
const REACT_APP_RAM_CONTRACT_ADDRESS = process.env.REACT_APP_RAM_CONTRACT_ADDRESS
const REACT_APP_NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
const REACT_APP_MP_WALLET_ADDRESS = process.env.REACT_APP_MP_WALLET_ADDRESS;
const TIMEZONE_API = process.env.TIMEZONE_API
const REACT_APP_WEB3AUTH_VERIFIER_NAME = process.env.REACT_APP_WEB3AUTH_VERIFIER_NAME
const REACT_APP_WEB3AUTH_NETWORK = process.env.REACT_APP_WEB3AUTH_NETWORK
const REACT_APP_LOGIN_CALLBACK_WHITELIST = process.env.REACT_APP_LOGIN_CALLBACK_WHITELIST
const IS_LEVICA_UI = false // TODO:: TO DELETE CONFIG KEY
const REACT_APP_ENV = process.env.REACT_APP_ENV
const REACT_APP_LEVICA_APP_DEEP_LINK = process.env.REACT_APP_LEVICA_APP_DEEP_LINK
const REACT_APP_CODE_D_MEASUREMENT_API_URL = process.env.REACT_APP_CODE_D_MEASUREMENT_API_URL

export {
    AWS_S3_BUCKET_DIRECTORY,
    AWS_S3_ENDPOINT,
    GOOGLE_ANALYTICS_MEASUREMENT_ID,
    REACT_APP_GOOGLE_TAG_MANAGER_ID,
    REACT_APP_BLOCKCHAIN_API_URL,
    REACT_APP_WALLET_CLIENT_ID,
    REACT_APP_RPC_TARGET,
    REACT_APP_BLOCK_EXPLORER,
    REACT_APP_CHAIN_ID,
    REACT_APP_INCUBATOR_CONTRACT_ADDRESS,
    REACT_APP_ARCANA_CONTRACT_ADDRESS,
    REACT_APP_PERSONA_CONTRACT_ADDRESS,
    REACT_APP_ANIMA_CONTRACT_ADDRESS,
    REACT_APP_TOKEN_CONTRACT_ADDRESS,
    REACT_APP_EGG_CONTRACT_ADDRESS,
    REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS,
    REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS,
    REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS,
    REACT_APP_RAM_CONTRACT_ADDRESS,
    REACT_APP_NULL_ADDRESS,
    REACT_APP_MP_WALLET_ADDRESS,
    REACT_APP_WEB3AUTH_VERIFIER_NAME,
    REACT_APP_WEB3AUTH_NETWORK,
    REACT_APP_LOGIN_CALLBACK_WHITELIST,
    TIMEZONE_API,
    IS_LEVICA_UI,
    REACT_APP_ENV,
    REACT_APP_LEVICA_APP_DEEP_LINK,
    REACT_APP_CODE_D_MEASUREMENT_API_URL,
}
